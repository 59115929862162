<template>
	<div>
		<b-modal
			id="bill-to-pay-modal"
			hide-header
			hide-footer
			centered
			size="lg"
			@hidden="onHidden"
			@show="onShow"
		>
			<div class="modal-header">
				<div class="modal-title">
					Conta a pagar
					<span class="help">(opcional)</span>
				</div>
				<span class="icon-box">
					<v-close class="icon stroke" @click="closeModal"/>
				</span>
			</div>
			<div class="around-content">
				<validation-observer ref="BillToPayForm">
					<div class="row">

						<div class="col-6">
							<b-form-group id="category_id">
								<label for="category_id">Categoria</label>
								<multiselect
									id="category_id"
									v-model="form.category"
									track-by="id"
									label="name"
									placeholder="Selecionar"
									:options="categories"
									:allow-empty="false"
									:option-height="40"
									:showLabels="false"
									:showNoResults="false"
									class="with-border"
									:disabled="onlendingExtract ? true :  false"
								>
									<template slot="caret">
										<div class="chevron">
											<v-chevron-down/>
										</div>
									</template>
									<template slot="singleLabel" slot-scope="{ option }">
										{{ option.name }}
									</template>

									<template slot="noOptions"> Nenhuma opção</template>

									<template slot="noResult"> Nenhum resultado</template>
								</multiselect>
								<div
									v-if="validated && !form.category"
									class="custom-invalid-feedback"
								>
									Campo obrigatório
								</div>
							</b-form-group>
						</div>

						<div class="col-6">
							<b-form-group id="filed_competence">
								<label for="field_competence">
									Competência
									<span class="help">(opcional)</span>
								</label>
								<b-form-input autocomplete="off" v-model="form.competence"/>
							</b-form-group>
						</div>

						<!-- <div class="col-4">
						  <b-form-group id="field_payment_method">
							<label for="field_payment_method">Método de pagamento</label>
							<multiselect
							  v-model="form.payment_method"
							  track-by="id"
							  label="name"
							  placeholder="Selecionar"
							  :options="paymentMethods"
							  :allow-empty="false"
							  :option-height="40"
							  :showLabels="false"
							  :showNoResults="false"
							  class="with-border"
							  :disabled="form.id !== null"
							>
							  <template slot="caret">
								<div class="chevron">
								  <v-chevron-down />
								</div>
							  </template>

							  <template slot="singleLabel" slot-scope="{ option }">
								{{ option.name }}
							  </template>

							  <template slot="noOptions"> Nenhuma opção </template>

							  <template slot="noResult"> Nenhum resultado </template>
							</multiselect>

							<div
							  v-if="validated && !form.payment_method"
							  class="custom-invalid-feedback"
							>
							  Campo obrigatório
							</div>
						  </b-form-group>
						</div> -->

						<!-- <div class="col-4">
						  <b-form-group id="field_amount">
							<label for="field_amount">Valor total</label>
							<money
							  class="form-control"
							  v-model="form.amount"
							  :disabled="form.id !== null"
							/>
							<div
							  v-if="validated && !form.amount"
							  class="custom-invalid-feedback"
							>
							  Campo obrigatório
							</div>
						  </b-form-group>
						</div> -->

						<div :class="{ 'col-4': !onlendingExtract, 'col-8': onlendingExtract }">
							<v-beneficiaries-select
								v-model="form.beneficiary"
								:value="form.beneficiary"
								:options="beneficiaries"
								optional
								label="Favorecido"
								:onlendingExtract="Boolean(onlendingExtract)"
							/>
						</div>

						<div class="col-4" v-if="!onlendingExtract">
							<v-patient-input
								:required="false"
								v-model="form.patient"
							/>
						</div>

						<div class="col-4">
							<b-form-group>
								<label for="cost-center">
									Centro de custo
									<span class="help">(opcional)</span>
								</label>
								<v-checkbox-select
									id="cost-center"
									optionLabel="label"
									trackBy="value"
									:value="selectedCostCenters"
									:options="costCenterOptions"
									v-on:onChange="changeSelectedCostCenters"
									:key="selectedCostCenters.length"
								/>
							</b-form-group>
						</div>

						<div class="col-4">
							<b-form-group>
								<label for="recurly-repeat">
									Repetição da conta
									<span class="help">(opcional)</span>
								</label>

								<multiselect
									id="recurly-repeat"
									track-by="id"
									label="name"
									v-model="recurly_repeat"
									:options="recurly_repeats"
									:option-height="40"
									:showLabels="false"
									:searchable="false"
									:allowEmpty="false"
									:showNoResults="false"
									placeholder="Selecionar..."
									class="with-border"
								>
									<template slot="caret">
										<div class="chevron">
											<v-chevron-down/>
										</div>
									</template>
									<template slot="noOptions"> Nenhuma opção</template>
								</multiselect>
							</b-form-group>
						</div>

						<div class="col-4">
							<b-form-group>
								<label for="recurly-rating">
									Classificação
									<span class="help">(opcional)</span>
								</label>

								<multiselect
									id="recurly-rating"
									track-by="id"
									label="name"
									v-model="recurly_rating"
									:options="recurly_ratings"
									:option-height="40"
									:showLabels="false"
									:searchable="false"
									:allowEmpty="false"
									:showNoResults="false"
									placeholder="Selecionar..."
									class="with-border"
									:disabled="!recurly_repeat"
								>
									<template slot="caret">
										<div class="chevron">
											<v-chevron-down/>
										</div>
									</template>
									<template slot="noOptions"> Nenhuma opção</template>
								</multiselect>
							</b-form-group>
						</div>

						<div class="col-4">
							<b-form-group>
								<label for="recurly-repeat-at">
									Repetir até
									<span class="help">(opcional)</span>
								</label>

								<date-picker
									id="recurly-repeat-at"
									class="full"
									v-model="recurly_repeat_at"
									format="DD/MM/YYYY"
									placeholder="DD/MM/AAAA"
									:clearable="false"
									:lang="langDatePicker"
									:disabled="!recurly_repeat || !recurly_rating"
								/>
							</b-form-group>
						</div>

						<!-- <div class="col-4">
						  <b-form-group id="field_due_date">
							<label for="field_due_date">Data de vencimento</label>
							<date-picker
							  class="full"
							  v-model="form.due_date"
							  format="DD/MM/YYYY"
							  placeholder="DD/MM/AAAA"
							  :clearable="false"
							  :lang="langDatePicker"
							  :disabled="form.id !== null"
							></date-picker>
							<div
							  v-if="validated && !form.due_date"
							  class="custom-invalid-feedback"
							>
							  Campo obrigatório
							</div>
						  </b-form-group>
						</div> -->

						<!-- <div class="col-4">
						  <b-form-group id="field_bank_account">
							<label for="field_bank_account">Conta bancária</label>
							<multiselect
							  v-model="form.bank_account"
							  track-by="id"
							  label="name"
							  placeholder="Selecionar"
							  :options="bankAccounts"
							  :allow-empty="false"
							  :option-height="40"
							  :showLabels="false"
							  :showNoResults="false"
							  class="with-border"
							  :disabled="false"
							>
							  <template slot="caret">
								<div class="chevron">
								  <v-chevron-down />
								</div>
							  </template>

							  <template slot="singleLabel" slot-scope="{ option }">
								{{ option.name }}
							  </template>

							  <template slot="noOptions"> Nenhuma opção </template>

							  <template slot="noResult"> Nenhum resultado </template>
							</multiselect>
							<div
							  v-if="validated && !form.bank_account"
							  class="custom-invalid-feedback"
							>
							  Campo obrigatório
							</div>
						  </b-form-group>
						</div> -->
            <div class="col-12 mb-4" v-if="showEntryInfo">
              <WarehouseEntryInfo :visible="false" />
            </div>
						<div class="col-12">
							<b-form-group id="field_description">
								<label for="field_description">Descrição</label>
								<b-form-input
									autocomplete="off"
									v-model="form.description"
									placeholder="Adicionar observações"
								/>

								<div
									v-if="validated && !form.description"
									class="custom-invalid-feedback"
								>
									Campo obrigatório
								</div>
							</b-form-group>
						</div>
					</div>
				</validation-observer>

				<v-payments
					:validated="validated"
					:bill.sync="form"
					:totalAmount="totalAmount"
					:onlendingExtract="Boolean(onlendingExtract)"
					:onlendingValue="form.amount"
				/>

				<div :class="{ 'wrapper-button': true, deletable: form.id }">
					<b-button
						v-can="'FiConPag5'"
						v-if="form.id"
						class="wh-button danger"
						variant="danger-outline"
						size="lg"
						@click="onDeleteClick"
					>
						Apagar conta
					</b-button>
					<b-button
						v-if="!form.id"
						v-can="'FiConPag2'"
						class="wh-button"
						variant="primary"
						size="lg"
						@click="createBillToPay"
					>
						Salvar conta a pagar
					</b-button>
					<b-button
						v-else
						v-can="'FiConPag4'"
						class="wh-button"
						variant="primary"
						size="lg"
						@click.prevent="updateBillToPay"
					>
						Atualizar conta a pagar
					</b-button>
				</div>
			</div>
		</b-modal>

		<v-delete-bill-to-pay-modal
			:description="form.description"
			:confirmedDelete="confirmedDelete"
			:confirmDeleteThis="recurrenceDeleteThis"
			:confirmDeleteAll="recurrenceDeleteAll"
			:is-recurrence="Boolean(recurly_repeat)"
		/>

		<update-bill-to-pay-modal
			:description="form.description"
			:confirmUpdateThis="recurrenceUpdateThis"
			:confirmUpdateAll="recurrenceUpdateAll"
			:is-recurrence="Boolean(recurly_repeat)"
		/>

	</div>
</template>
<script>
import {track} from '@/utils/mixPanel'
import {EventBus} from '@/utils/eventBus'
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import PatientInput from '@/components/General/PatientInput'
import BeneficiarySelect from '@/components/General/BeneficiarySelect'
import Payments from '@/components/Financial/Payments'
import DeleteBillToPayModal from '@/components/General/DeleteBillToPayModal'
import CheckboxSelect from '@/components/CheckboxSelect.vue'
import {getCurrentClinic} from '@/utils/localStorageManager'

export default {
	name: 'BillToPayModal',
	components: {
		'v-close': Close,
		'v-chevron-down': ChevronDown,
		'v-patient-input': PatientInput,
		'v-beneficiaries-select': BeneficiarySelect,
		'v-checkbox-select': CheckboxSelect,
		'v-payments': Payments,
		'v-delete-bill-to-pay-modal': DeleteBillToPayModal,
    WarehouseEntryInfo: () => import('@/components/Warehouse/WarehouseEntryInfo'),
		UpdateBillToPayModal: () => import('@/components/General/ConfirmUpdateBillToPayModal')
	},
	props: {
		billToPayId: String,
		onlendingExtract: Object,
    showEntryInfo: {
      type: Boolean,
      default: false
    }
	},
	mounted() {
		this.form = this.getDefaultForm()
	},
	data() {
		return {
			clinic: getCurrentClinic(),
			validated: false,
			showFieldsInstallment: false,
			toDelete: null,
			recurly_repeat: null,
			recurly_repeat_at: null,
			recurly_rating: null,
			update_recurly: undefined,
			delete_recurly: undefined,

			errors: {},
			categories: [],
			patients: [],
			beneficiaries: [],

			form: {},

			langDatePicker: {
				formatLocale: {
					weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'] // DIAS DA SEMANA NO CAMPO DATA
				}
			},

			selectedCostCenters: [],
			costCenterOptions: [],
			recurly_repeats: [
				{id: null, name: 'Não se repete'},
				{id: '1 month', name: 'Mensal'},
				{id: '1 year', name: 'Anual'},
			],
			recurly_ratings: [
				{id: 'fixed', name: 'Custo fixo'},
				{id: 'variable', name: 'Custo variável'},
			],
			totalAmount: null
		}
	},

	computed: {
		is_recurrence() {
			return this.form.recurly_repeat !== null
		},
	},

	methods: {
		getDefaultForm: () => ({
			id: null,
			type: 'bill_to_pay',
			category: null,
			beneficiary: null,
			patient: null,
			costCenters: null,
			description: null,
			competence: null,
			payments: [{
				payment_method: null,
				amount: 0,
				due_date: null,
				bank_account: null,
				installments: [],
			}],
		}),
		async onShow() {
			const loading = this.$loading.show()

			if (!this.onlendingExtract) this.categories = await this.getCategories()
			await this.getCostCenters()
			if (this.billToPayId) {
				await this.getBillToPay()
				await this.getBillCostCenters()
			}
			if (this.onlendingExtract) await this.getOnlendingForm()
			loading.hide()
		},
		onHidden() {
			this.selectedCostCenters = []
			this.form = this.getDefaultForm()
			this.recurly_repeat = null
			this.recurly_repeat_at = null
			this.recurly_rating = null
			this.$bvModal.hide('bill-to-pay-modal')
		},
		async getBillCostCenters() {
			await this.api.getBillCostCenters(this.billToPayId)
				.then(res => {
					this.selectedCostCenters = []
					res.data.map(costCenter => {
						const option = this.costCenterOptions.find(el => {
							return el.value === costCenter.cost_center_id
						})
						this.selectedCostCenters.push({
							value: option?.value,
							label: option?.label
						})
					})
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		async getCostCenters() {
			await this.api.getCostCenters(this.clinic.id)
				.then(res => {
					this.costCenterOptions = []
					res.data.map((center) => {
						if (center.active) {
							this.costCenterOptions.push({
								value: center.id,
								label: center.name,
							})
							if (center.is_default && !this.billToPayId) {
								this.selectedCostCenters = [{
									value: center.id,
									label: center.name,
								}]
								this.changeSelectedCostCenters(this.selectedCostCenters)
							}
						}
					})
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		changeSelectedCostCenters(value) {
			this.form.costCenters = value.map(costCenter => (costCenter.value))
		},
		async getCategories() {
			try {
				const response = await this.api.getCategories(this.clinic.id, 'Saída')
				this.categories = response.data.filter(el => !['Procedimento', 'Produto'].includes(el.name));
				return this.categories
			} catch (error) {
				this.$toast.error(error.message)
				throw new Error(400)
			}
		},
		async getOnlendingCategory() {
			try {
				const response = await this.api.getCategories(this.clinic.id, 'Saída', false, true)
				this.categories = response.data.filter(el => ['Repasse'].includes(el.name));
				return this.categories
			} catch (error) {
				this.$toast.error(error.message)
				throw new Error(400)
			}
		},
		async getBillToPay() {
			try {
				const response = await this.api.getBill(this.billToPayId);
				if (response.data.id) {
					this.form = response.data
					if (response.data.recurrence) {
						this.recurly_repeat = this.recurly_repeats.find(el => el.id === response.data.repeat_on)
						this.recurly_rating = this.recurly_ratings.find(el => el.id === response.data.rating)
						this.recurly_repeat_at = response?.data?.repeat_at ? new Date(response?.data?.repeat_at?.replace(/-/g, '/')) : null
					}
				}
			} catch (error) {
				this.$toast.error(error.message)
				throw new Error(400)
			}
		},
		getDataPayload() {
			return {
				type: this.form.type,
				clinic_id: this.clinic.id,
				category_id: this.form.category.id,
				patient_id: this.form.patient ? this.form.patient.id : null,
				beneficiary_id: this.form.beneficiary ? this.form.beneficiary.id : null,
				competence: this.form.competence || null,
				description: this.form.description,
				costCenters: this.form.costCenters || null,
				recurly: {
					repeat: this.recurly_repeat?.id || null,
					repeat_at: this.recurly_repeat_at,
					rating: this.recurly_rating?.id || 'fixed',
					update_recurly: this.update_recurly,
					delete_recurly: this.delete_recurly,
				},
				payments: this.form.payments.map(payment => ({
					id: payment.id,
					amount: payment.amount,
					due_date: payment.due_date,
					bank_account_id: payment.bank_account.id,
					payment_method_id: payment.payment_method.id,
					installments: payment.installments
				})),
			}
		},

		async createBillToPay() {
			if (!this.isValidForm()) return
			const isLoading = this.$loading.show()

			try {

				const data = this.getDataPayload()

				await this.api.createBill(data)
					.then(res => {
						track('conta_a_pagar', {
							clinic: this.clinic.name,
						});

						this.clearForm()
						this.$toast.success('Conta a pagar cadastrada com successo!')
						this.$bvModal.hide('bill-to-pay-modal')

						EventBus.$emit('reloadBillsToPay')
						this.$emit('createBill', {
							id: res.data.id,
							payments: data.payments
						})
						this.$emit('onHide')
						isLoading.hide()
					})
			} catch (error) {
				this.$toast.error(error.message)
			} finally {
				isLoading.hide()
			}
		},

		async updateBillToPay() {
			const data = this.getDataPayload();

			if (this.form.recurrence && !this.update_recurly) {
				this.$bvModal.show('confirm-update-recurrence')
				return
			}

			if (!this.isValidForm()) return
			const isLoading = this.$loading.show()

			try {
				await this.api.updateBill(this.form.id, data)
				this.clearForm()
				this.update_recurly = undefined
				this.delete_recurly = undefined
				this.$toast.success('Conta a pagar atualizada com sucesso!')
				this.$bvModal.hide('bill-to-pay-modal')

				EventBus.$emit('reloadBillsToPay')
				this.$emit('onHide')
				isLoading.hide()
			} catch (error) {
				this.$toast.error(error.message)
			} finally {
				isLoading.hide()
			}
		},

		clearForm() {
			this.form = {
				id: null,
				category: null,
				patient: null,
				beneficiary: null,
				payment_method: null,
				bank_account: null,
				competence: null,
				amount: 0,
				due_date: null,
				description: null
			}
			this.validated = false
			this.showFieldsInstallment = false
		},

		isValidForm() {
			this.validated = true
			return (
				this.form.category &&
				this.form.description &&
				this.form.payments.every(payment =>
					payment.payment_method &&
					payment.bank_account &&
					payment.amount &&
					payment.due_date &&
					payment.installments.length
				) &&
				this.isValidSumAmount()
			)
		},
		isValidState(model) {
			return this.errors[model] !== true
		},
		closeModal() {
			this.clearForm();
			this.$emit('onHide');
			this.$bvModal.hide('bill-to-pay-modal')
		},
		showFieldsInstallmentMethod() {
			if (!this.showFieldsInstallment) {
				this.showFieldsInstallment = true
			} else {
				this.showFieldsInstallment = false
			}
		},
		async onDeleteClick() {
			this.$bvModal.show('delete-bill-to-pay-modal');
		},

		async recurrenceDeleteThis(confirm) {
			if (confirm) {
				this.delete_recurly = 'this'
				this.confirmedDelete(confirm, 'this')
			}
			this.$bvModal.hide('delete-bill-to-pay-modal');
		},

		async recurrenceUpdateThis(confirm) {
			if (confirm) {
				this.update_recurly = 'this'
				this.updateBillToPay()
			}

			this.$bvModal.hide('confirm-update-recurrence');
		},

		async recurrenceDeleteAll(confirm) {
			if (confirm) {
				this.delete_recurly = 'all'
				this.confirmedDelete(confirm, 'all')
			}
			this.$bvModal.hide('delete-bill-to-pay-modal');
		},

		async recurrenceUpdateAll(confirm) {
			if (confirm) {
				this.update_recurly = 'all'
				this.updateBillToPay()
			}
			this.$bvModal.hide('confirm-update-recurrence');
		},

		async confirmedDelete(confirm, recurly = undefined) {
			if (confirm) {
				const isLoading = this.$loading.show();
				try {
					if (recurly) {
						await this.api.deleteBillRecurrence(this.form.id, recurly)
					} else {
						await this.api.deleteBill(this.form.id);
					}

					isLoading.hide();
					EventBus.$emit('reloadBillsToPay');
					this.$toast.success('Conta a receber apagada com sucesso');
					this.closeModal();
				} catch (ex) {
					this.$toast.error(ex.message);
					isLoading.hide();
				}
			}
			this.$bvModal.hide('delete-bill-to-pay-modal');
		},
		async getOnlendingForm() {
			if (this.onlendingExtract) {
				this.categories = await this.getOnlendingCategory()

				this.form = {
					id: null,
					type: 'bill_to_pay',
					category: this.categories.find(option => option.name === 'Repasse'),
					patient: null,
					beneficiary: this.onlendingExtract.beneficiary,
					costCenters: null,
					description: `Repasse para ${this.onlendingExtract.beneficiary.name}`,
					competence: null,
					amount: this.onlendingExtract.onlending_value,
					payments: this.form.payments
				}

				this.form.payments[0].amount = this.onlendingExtract.onlending_value
				this.totalAmount = this.onlendingExtract.onlending_value
			}
		},
		isValidSumAmount() {
			if (this.onlendingExtract) {
				let total = 0
				this.form.payments.forEach(payment => {
					total += payment.amount
				});
				if (parseFloat(total.toFixed(2)) !== parseFloat(this.form.amount.toFixed(2))) {
					this.$toast.warning('A soma dos métodos de pagamento deve ser igual ao valor do repasse')
					return false
				}
			}
			return true
		}
	}
}
</script>
<style lang="scss">
#bill-to-pay-modal {

	.modal-body {
		padding: 0 !important;
		margin: 0 !important;

		.modal-header {
			width: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			padding: 24px !important;

			.modal-title {
				font-family: 'Nunito Sans';
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 28px;
				color: #525C7A;
			}

			.icon {
				width: 25px;
				fill: var(--neutral-500);
			}
		}
	}

	.around-content {
		padding: 24px 24px 0 24px;

		.multiselect {
			.multiselect__single {
				color: #525C7A !important;
			}
		}

		.md-button {
			text-align: right;
			margin: 20px 0;
		}
	}

	.wrapper-button {
		width: 100%;
		display: inline-flex;
		margin: 20px 0 !important;
		justify-content: flex-end;

		&.deletable {
			justify-content: space-between;
		}


		button {
			font-family: 'Nunito Sans';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;

			&.danger {
				color: var(--states-error);
			}
		}
	}


}
</style>
